<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Counterparty from "../../../models/Counterparty";

    export default {
        name: "Main",
        props: {
            id: Number | String
        },
        data() {
            return {
                model: new Counterparty({
                    id: this.id
                })
            }
        },
        watch: {
            $route: function (route) {
                // update data only when model`s id changed
                if (route.params.id != this.model.id) {
                    this.prepareData();
                }
            }
        },
        methods: {
            async prepareData() {
                this.model.set("id", this.$route.params.id);
                try {
                    await this.model.fetch();
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных.");
                }
            },
            onBackClick() {
                window.history.back();
            }
        },
        created() {
            // загружаем данные, когда представление создано
            // и данные реактивно отслеживаются
            this.prepareData();
        },
    }
</script>


<template>
    <div v-loading="model.loading">
        <h1>
            <el-page-header @back="onBackClick"
                            title=""
                            :content="model.$.name"></el-page-header>
        </h1>

        <div>
            <el-menu :router="true" :default-active="$route.name" mode="horizontal">
                <el-menu-item index="counterparty-edit" :route="{name:'counterparty-edit',params:{model}}">
                    Основное
                </el-menu-item>
                <el-menu-item index="counterparty-shipping-addresses" :route="{name:'counterparty-shipping-addresses', params:{model}}">
                    Адреса доставки
                </el-menu-item>
                <el-menu-item index="counterparty-has-group-list" :route="{name:'counterparty-has-group-list', params:{model}}">
                    Группы
                </el-menu-item>
                <el-menu-item index="counterparty-order-history" :route="{name:'counterparty-order-history', params:{model}}">
                    История Заказов
                </el-menu-item>
            </el-menu>
            <router-view :model="model"></router-view>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    .el-menu {
        margin: 20px 0;
    }
</style>