/**
 * The Order`s item model
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Base from "./BaseModel";

export default class OrderItem extends Base {

    static entityName = 'order-item';


    defaults() {
        return {
            id: null,
            order_id: null,
            title: '',
            cost: 0,
            count: 1,
            total: 0,
            discount_percent: 0
        }
    }
}