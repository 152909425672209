<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import BaseForm from "../../../components/Form";
    import CounterpartyShippingAddress from "../../../models/CounterpartyShippingAddress";

    export default {
        name: "ShippingAddressForm",
        components: {
            BaseForm
        },
        props: {
            model: {
                type: CounterpartyShippingAddress,
                default: () => new CounterpartyShippingAddress()
            }
        },
        methods: {
            onBackHandler(r) {
                this.$emit('backhandler');
            }
        }
    }
</script>


<template>
    <base-form :model="model"
               :save-commands="false"
               :back-handler="onBackHandler">
        <el-form-item v-if="!model.$.is_primary">
            <el-switch v-model="model.is_primary"
                       :active-value="1"
                       :inactive-value="0"
                       active-text="Основной адрес"></el-switch>
        </el-form-item>
        <el-form-item :label="'Название'"
                      :error="model.errors.name"
                      :show-message="!!model.errors.name"
                      required>
            <el-input v-model="model.name"
                      placeholder="например 'Офис' или 'Домашний'"
                      clearable
                      :maxlength="45"></el-input>
        </el-form-item>
        <el-form-item label="Адрес"
                      required
                      :error="model.errors.address"
                      :show-message="!!model.errors.address">
            <el-input type="textarea"
                      v-model="model.address"
                      :maxlength="128"
                      :show-word-limit="true"
                      placeholder="123456, г.СПб ул. Ленина 6, оф. 666"
                      :autosize="{ minRows: 3}"></el-input>
        </el-form-item>
        <el-form-item label="Примечания"
                      :error="model.errors.notes"
                      :show-message="!!model.errors.notes">
            <el-input type="textarea"
                      v-model="model.notes"
                      :maxlength="255"
                      :show-word-limit="true"
                      placeholder="Небольшой текст для заметок..."
                      :autosize="{ minRows: 3}"></el-input>
        </el-form-item>
    </base-form>
</template>


<style scoped>

</style>