/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class CounterpartyShippingAddress extends BaseModel {
    static entityName = 'counterparty-shipping-address';

    defaults() {
        return {
            ...super.defaults(),
            counterparty_id: null,
            name: '',
            address: '',
            notes: '',
            is_primary: 0
        }
    }
}
