/**
 * The Order`s item model
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Base from "./BaseModel";
import axios from "axios";
import Formatter from "@/utils/Formatter";
import Order from "@/models/Order";

export default class OrderPayment extends Base {
    static entityName = 'order-payment';
    static MODE_INCOME = 'income';
    static MODE_REFUND = 'refund';

    defaults() {
        return {
            id: null,
            type: '',
            value: 0,
            comment: '',
            order_id: null,
            created_at: null,
            mode: OrderPayment.MODE_INCOME
        }
    }

    mutations() {
        return {
            ...super.mutations(),
            created_at: (date) => Formatter.asDateTime(date, 'yyyy-MM-DD hh:mm:ss')
        };
    }

    static async getTypes() {
        let resp = await axios.get('/admin/order-payment/types');
        return resp.data;
    }


    /**
     * @return {Order} Связанный Заказ
     * */
    getOrder() {
        if (!this.__orderInstance) {
            this.__orderInstance = new Order({
                id: this.$.order_id
            });
        }
        return this.__orderInstance;
    }
}