<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Model from "../../../models/CounterpartyGroup";
    import BaseForm from "../../../components/Form";

    export default {
        name: "Form",

        components: {
            BaseForm
        },

        props: {
            id: Number | String
        },


        data() {
            return {
                model: new Model({
                    id: this.id
                })
            }
        },


        computed: {
            title: (vm) => vm.model.id ? vm.model.$.name : 'Создать'
        }
    }
</script>


<template>
    <div class="humanize-container" v-loading="model.loading">
        <base-form :title="title"
                   backTitle="Группы"
                   :model="model">
            <el-form-item :label="'Название'"
                          :error="model.errors.name"
                          :show-message="!!model.errors.name"
                          required>
                <el-input v-model="model.name"
                          placeholder="Группа 1"
                          clearable
                          :maxlength="64"></el-input>
            </el-form-item>
            <el-form-item label="Примечания"
                          :error="model.errors.notes"
                          :show-message="!!model.errors.notes">
                <el-input type="textarea"
                          v-model="model.notes"
                          :maxlength="255"
                          :show-word-limit="true"
                          placeholder="Небольшой текст описания для группы..."
                          :autosize="{ minRows: 2}"></el-input>
            </el-form-item>
        </base-form>
    </div>
</template>


<style scoped lang="scss">
</style>