<script>
    /**
     * The list of Counterparty`s groups
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Counterparty from "../../../models/Counterparty";
    import CounterpartyGroup from "../../../models/CounterpartyGroup";
    import BaseStore from "../../../models/BaseStore";
    import map from "lodash/map";
    import filter from "lodash/filter";
    import remove from "lodash/remove";
    import differenceWith from "lodash/differenceWith";

    export default {
        name: "GroupList",
        props: {
            model: {
                type: Counterparty,
                required: true
            }
        },
        data() {
            return {
                modelHasGroups: this.model.getHasGroups({
                    autoLoad: true,
                    fetchParams: {
                        limit: 0,
                        sort: '',
                        expand: 'groupName'
                    }
                }),
                allGroups: new BaseStore([], {
                    autoLoad: true,
                    entityName: 'counterparty-group',
                    fetchParams: {
                        limit: 0,
                        sort: '',
                        expand: 'groupName'
                    }
                }),
                selectedGroupIds: []
            }
        },
        computed: {
            availableGroups: vm => differenceWith(vm.allGroups.models, vm.modelHasGroups.models, (group, hasGroup) => {
                return group.id === hasGroup.counterparty_group_id;
            })
        },
        methods: {
            async saveModel() {
                try {
                    await this.model.save();
                    this.selectedGroupIds = [];
                } catch (e) {
                    this.$notify.error("Ошибка сохранения");
                }
            },
            async onAddClick() {
                let groupIds = [
                    ...map(this.modelHasGroups.models, m => m.counterparty_group_id),
                    ...this.selectedGroupIds
                ];

                this.model.set("group_ids", groupIds);
                await this.saveModel();
                this.modelHasGroups.reload();
            },
            async onRemoveClick(hasGroup) {
                let groupIds = map(
                    filter(this.modelHasGroups.models, m => m.counterparty_group_id !== hasGroup.counterparty_group_id),
                    m => m.counterparty_group_id);

                this.model.set("group_ids", groupIds);
                await this.saveModel();

                //remove from store after success save
                this.modelHasGroups.remove({counterparty_group_id: hasGroup.counterparty_group_id});
            }
        }
    }
</script>


<template>
    <div class="humanize-container--short"
         v-loading="modelHasGroups.loading || model.saving">

        <div class="toolbar">
            <div class="grow">
                <el-select v-model="selectedGroupIds"
                           :loading="allGroups.loading"
                           multiple
                           filterable
                           placeholder="Выберите группы...">
                    <el-option v-for="item in availableGroups"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="toolbar-item">
                <el-button type="primary"
                           @click="onAddClick">Добавить
                </el-button>
            </div>
        </div>

        <el-table :data="modelHasGroups.models"
                  :empty-text="`${model.$.name} еще не добавлен ни в одну группу...`"
                  :show-header="false">
            <el-table-column prop="groupName"></el-table-column>
            <el-table-column :width="50">
                <template v-slot:default="{row:hasGroup}">
                    <el-button circle
                               icon="el-icon-close"
                               size="mini"
                               @click="onRemoveClick(hasGroup)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>


<style lang="scss" scoped>
    .el-button {
        &.is-circle {
            border: none;
        }
    }
</style>