<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Counterparty from "../../../models/Counterparty";
    import ShippingAddressForm from "./ShippingAddressForm";
    import CounterpartyShippingAddress from "../../../models/CounterpartyShippingAddress";

    export default {
        name: "ShippingAddresses",
        components: {
            ShippingAddressForm
        },
        props: {
            model: {
                type: Counterparty,
                required: true
            }
        },

        data() {
            return {
                store: this.getStore(),
                editingRecord: null,
                isDialogVisible: false
            }
        },

        watch: {
            $route: function () {
                this.store = this.getStore();
            }
        },
        computed: {
            records: vm => vm.store.models,
            dialogTitle: vm => (vm.editingRecord && vm.editingRecord.id) ? vm.editingRecord.$.name : 'Добавить адрес доставки'
        },

        methods: {
            getStore() {
                return this.model.getShippingAddresses({
                    autoLoad: true,
                    fetchParams: {
                        limit: 0,
                        sort: '-is_primary,name' // primary want to be first
                    }
                });
            },
            createForm(record) {
                if (!record) {
                    record = new CounterpartyShippingAddress({
                        counterparty_id: this.model.id
                    });
                }
                this.editingRecord = record;
                this.isDialogVisible = true;
            },
            onBackHandler() {
                this.isDialogVisible = false;
                this.store.reload();
            }
        }
    }
</script>


<template>
    <div v-loading="store.loading"
         class="humanize-container">

        <p class="empty-text"
           v-if="store.size() == 0">У {{model.$.name}} еще не создано адресов доставки...</p>

        <el-button @click="createForm()"
                   type="primary">Создать
        </el-button>

        <el-collapse class="humanize-container--short">
            <el-collapse-item v-for="record in records"
                              :name="record.$.name"
                              :key="record.id">
                <template slot="title">
                    <i v-if="record.$.is_primary" class="el-icon-location"></i> {{record.$.name}}
                </template>
                <address>{{record.$.address}}</address>
                <div class="notes">{{record.$.notes}}</div>
                <div class="toolbar">
                    <el-button @click="createForm(record)">Редактировать</el-button>
                </div>
            </el-collapse-item>
        </el-collapse>

        <el-dialog :visible.sync="isDialogVisible"
                   :title="dialogTitle">
            <shipping-address-form :model="editingRecord"
                                   @backhandler="onBackHandler"></shipping-address-form>
        </el-dialog>
    </div>
</template>


<style scoped lang="scss">
    .el-collapse {
        margin: 20px 0 0 0;
        border: none;
    }

    .toolbar {
        margin: 20px 0 0;
    }

    .notes {
        font-size: smaller;
        color: silver;
    }
</style>