/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class CounterpartyGroup extends BaseModel {
    static entityName = 'counterparty-group';

    defaults() {
        return {
            ...super.defaults(),
            name: '',
            notes: ''
        }
    }
}
