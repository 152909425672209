<script>
    /**
     * Represent common simple data list with routing by item tap
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import BaseStore from "../models/BaseStore";
    import filter from "lodash/filter";
    import isString from "lodash/isString";

    export default {
        name: "List",
        props: {
            store: {
                type: BaseStore,
                required: true
            },
            createRoute: {
                type: String | Object
            },
            detailsRoute: {
                type: String | Object
            },
            filterable: {
                type: Boolean,
                default: true
            },
            filterPlaceholder: {
                default: 'Название...'
            },
            nameProp: {
                default: 'name'
            },
            /**
             * Method to use build meta string
             * @param {BaseModel} record
             * */
            getMetaString: {
                type: Function,
                default: (record) => ""
            },
            getItemCls:{
                type: Function,
                default: ({row, rowIndex}) => 'list-item'
            },
            toolsWidth: {
                type: String | Number,
                default: 35
            },
            /**
             * If true - multiple selection with alternate toolbar
             * */
            hasGroupOperations: {
                type: Boolean
            }
        },

        data() {
            return {
                filterText: '',
                selectedModels: [],
            }
        },

        computed: {
            filteredModels: (vm) => filter(vm.store.models, m => m.get(vm.nameProp).toLowerCase().indexOf(vm.filterText.toLowerCase()) > -1),
            isToolbarVisible: (vm) => vm.filterable || vm.createRoute,
            isMultipleSelection: vm => vm.hasGroupOperations
        },

        methods: {
            onRowSelect(currentRow, oldCurrentRow) {
                let route = this.detailsRoute;
                if (route) {
                    if (isString(route)) {
                        route = {
                            name: route
                        };
                    }

                    this.$router.push({
                        ...route,
                        params: {
                            id: currentRow && currentRow.id || null
                        }
                    });
                }
            },

            onAddClick() {
                let route = this.createRoute;
                if (route) {
                    if (isString(route)) {
                        route = {
                            name: route
                        };
                    }

                    this.$router.push({
                        ...route
                    });
                }
            },

            handleSelectionChange(val) {
                this.selectedModels = val;
            },
        }
    }
</script>


<template>
    <div class="datalist">

        <div class="toolbar"
             v-if="isToolbarVisible">
            <el-input v-if="filterable"
                      class="grow"
                      :placeholder="filterPlaceholder"
                      clearable
                      v-model="filterText">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>

            <slot name="toolbar">
                <div v-if="createRoute"
                     class="toolbar-item">
                    <el-tooltip content="Добавить"
                                class="toolbar-item">
                        <el-button type="primary"
                                   icon="el-icon-plus"
                                   @click="onAddClick">Добавить
                        </el-button>
                    </el-tooltip>
                </div>
            </slot>
        </div>

        <div v-if="selectedModels.length > 0"
             class="toolbar toolbar-alternate">
            <slot name="toolbar-alternate" :selectedModels="selectedModels">
                <div class="toolbar-item">Выбрано {{selectedModels.length}}:</div>
            </slot>
        </div>

        <el-table highlight-current-row
                  @selection-change="handleSelectionChange"
                  @current-change="onRowSelect"
                  :row-class-name="getItemCls"
                  :data="filteredModels">

            <el-table-column v-if="isMultipleSelection"
                             type="selection"
                             width="50"></el-table-column>

            <el-table-column>
                <template v-slot="{row:record}">
                    <slot name="item" :record="record">
                        <div class="item-name text-ellipsis">{{record[nameProp]}}</div>
                        <div class="metas">{{ getMetaString(record)}}</div>
                    </slot>
                </template>
            </el-table-column>

            <el-table-column :width="toolsWidth">
                <template v-slot="{row:record}">
                    <slot name="tools" :record="record">
                        <el-icon class="el-icon-arrow-right"></el-icon>
                    </slot>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>


<style lang="scss">
    .datalist {
        .el-table {
            &__row {
                cursor: pointer;
            }
        }
    }


    .metas {
        font-size: .7rem;
        line-height: .7rem;
        color: gray;
    }
</style>