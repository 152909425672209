<script>
/**
 * Shared component to display order list item view
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 *
 * Assume order fetched with following fields:
 *  fetchParams:{
 *      fields: 'id,number,delivery_date,manager_notes,total,client_name,client_phone',
 *      expand: 'statusText,payStatusText,counterpartyName'
 *  }
 */

import Order from "../../models/Order";

export default {
    name: "ListItem",
    props: {
        order: {
            type: Order,
            required: true
        }
    },
    computed: {
        courierName: vm => vm.order.courierName
    }
    , methods: {
        onAddressClick() {
            const text = this.order.delivery_address;
            console.log(text, this.order);
            if (!text || !text.length) {
                this.$message.error(`Адрес доставки не задан`);
                return;
            }
            navigator.clipboard.writeText(text);
            this.$message.success(`Адрес ${text} скопирован в буфер обмена`);
        }
    }
}
</script>


<template>
    <div class="order-list-item">
        <div class="title">
            <div class="item-name">#{{ order.number }}</div>
            <div class="metas">
                <div>{{ order.manager_notes }}</div>
            </div>
        </div>
        <div class="delivery">
            <div class="delivery--counterparty text-ellipsis">
                <router-link v-if="order.counterparty_id"
                             :to="`/counterparty/${order.counterparty_id}`"
                             class="counterparty-link"
                             title="Перейти в карточку Контрагента">
                    <i class="icon el-icon-link"></i>
                    {{ order.client_name }}
                </router-link>
                <span v-else>
                {{ order.client_name }}
        </span>
            </div>
            <div class="delivery--shipping" v-if="order.delivery_date">
                <el-icon class="icon el-icon-truck"></el-icon>
                <a class="action-link" @click.stop="onAddressClick"
                   title="Копировать адрес доставки в буфер обмена">
                    {{ order.delivery_date|date }}
                    <span v-if="order.delivery_address">, {{ order.delivery_address }}</span>
                    <span v-if="courierName">, {{ courierName }}</span>
                </a>
            </div>
            <div class="phone" v-if="order.client_phone">
                <el-icon class="icon el-icon-phone"></el-icon>
                <a :href="`tel:${order.client_phone}`"
                   class="client_phone action-link"
                   @click.stop>{{ order.client_phone }}</a>
            </div>
        </div>
        <div class="status">
            <div class="status--order">{{ order.statusText }}</div>
            <div class="status--pay">{{ order.payStatusText }}</div>
        </div>
        <div class="price">
            {{ order.total|currency }}
        </div>
    </div>
</template>


<style lang="scss" scoped>
.order-list-item {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;


    > * + * {
        margin-left: 10px;
    }

    .title {
        width: 150px;

        .metas {
            color: #2c3e50;
        }
    }

    .delivery {
        flex: 1;
        text-align: left;
    }

    .price {
        font-size: larger;
        white-space: nowrap;
        font-weight: 500;
        width: 90px;
        text-align: right;
    }

    .status {
        line-height: 14px;
        white-space: nowrap;
        width: 130px;

        &--pay {
            font-style: italic;
            color: silver;
        }
    }

    .counterparty-link {
        font-weight: bold;
    }

    .icon {
        margin-right: .5em;
    }
}
</style>