import Base from "./BaseModel";

/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
export default class OrderHistory extends Base {
    static entityName = 'order-history';

    /**
     * Category for system edits
     * */
    static CATEGORY_SYSTEM_EDIT = 'system';

    /**
     * Category when managers edit
     * */
    static CATEGORY_MANAGER_EDIT = 'edit';

    /**
     * Category for courier related action, e.g. via courier app
     * */
    static CATEGORY_COURIER_EDIT = 'courier';

    /**
     * Category for notifications
     * */
    static CATEGORY_NOTIFICATION = 'notification';


    /**
     * Subcategory for any payment related actions,
     * E.g. tax for user notification or system edit when payment received
     */
    static SUBCATEGORY_PAYMENT = 'payment';

}