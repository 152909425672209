<script>
    /**
     * The list of Counterparty Groups
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import CounterpartyGroup from "../../../models/CounterpartyGroup";
    import BaseStore from "../../../models/BaseStore";
    import SimpleList from "../../../components/SimpleList";
    import compact from "lodash/compact";

    export default {
        name: "List",
        components: {
            SimpleList
        },

        data() {
            return {
                store: new BaseStore([], {
                    entityName: 'counterparty-group',
                    model: CounterpartyGroup,
                    autoLoad: true,
                    filterText: '',
                    fetchParams: {
                        sort: 'name',
                        fields: 'id,name,notes',
                        limit: 0
                    }
                })
            }
        },

        methods: {
            getMetaString(model) {
                return compact([
                    model.notes
                ]).join(", ");
            }
        }
    }
</script>


<template>
    <div class="humanize-container"
         v-loading="store.loading">
        <simple-list :store="store"
                     :get-meta-string="getMetaString"
                     details-route="counterparty-group-edit"
                     create-route="counterparty-group-create"></simple-list>
    </div>
</template>


<style lang="scss" scoped>
</style>