<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import BaseStore from "../../../models/BaseStore";
    import Order from "../../../models/Order";
    import Counterparty from "../../../models/Counterparty";
    import SimpleList from "../../../components/SimpleList";
    import OrderListItem from "../../order/ListItem";

    export default {
        name: "OrderHistory",
        components:{
            SimpleList,
            OrderListItem
        },
        props: {
            model: {
                type: Counterparty,
                required: true
            }
        },
        data() {
            return {
                orders: new BaseStore([], {
                    model: Order,
                    autoLoad: true,
                    autoFilter: true,
                    autoSort: true,
                    sorter: {
                        property: 'created_at',
                        direction: BaseStore.SORT_DESC
                    },
                    internalFilters: {
                        counterparty: {
                            property: 'counterparty_id',
                            value: this.model.id
                        }
                    },
                    fetchParams: {
                        limit: 0,
                        fields: 'id,number,manager_notes,total,delivery_date,client_name,client_phone,client_notes',
                        expand: 'statusText,payStatusText,courierName'
                    }
                }),
            }
        }
    }
</script>


<template>
    <div class="humanize-container">
        <simple-list :store="orders"
                     name-prop="number"
                     details-route="order-basic"
                     :filterable="false">
            <template v-slot:item="{record}">
                <order-list-item :order="record"></order-list-item>
            </template>

        </simple-list>
    </div>
</template>


<style scoped>

</style>