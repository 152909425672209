<script>
    /**
     * The list of Counterparties
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Counterparty from "../../models/Counterparty";
    import compact from "lodash/compact";
    import BaseStore from "../../models/BaseStore";
    import LazyList from "../../components/LazyList";


    export default {
        name: "List",
        components: {
            LazyList
        },

        data() {
            return {
                store: new BaseStore([], {
                    entityName: 'counterparty',
                    model: Counterparty,
                    fetchParams: {
                        sort: 'name',
                        fields: 'id,name,inn,email,phone',
                        expand: 'groupNames',
                        filterText: ""
                    }
                })
            }
        },

        methods: {
            getMetaString(model) {
                return compact([
                    model.inn && `ИНН: ${model.inn}`,
                    model.email,
                    model.phone
                ]).join(", ");
            }
        }
    }
</script>


<template>
    <div class="humanize-container">
        <lazy-list :store="store"
                   filter-placeholder="Поиск: название, инн, фио, email ..."
                   :get-meta-string="getMetaString"
                   create-route="counterparty-create"
                   details-route="counterparty-edit"></lazy-list>
    </div>
</template>


<style lang="scss" scoped>
    .metas {
        font-size: .8rem;
        color: gray;
    }
</style>