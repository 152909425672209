<script>
    /**
     * The default behaviour Form
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import BaseModel from "../models/BaseModel";
    import first from "lodash/first";
    import keys from "lodash/keys";


    export default {
        name: "Form",

        props: {
            model: {
                type: BaseModel,
                required: true
            },
            title: String,
            backTitle: {
                type: String,
                default: ''
            },
            saveCommands: {
                type: Object | false,
                default: () => {
                    return {
                        back: "Создать и вернуться назад",
                        repeat: "Создать и повторить"
                    }
                }
            },
            saveText: {
                type: String
            },
            // Handler for back command
            backHandler: {
                type: Function,
                default: function () {
                    this.$router.back();
                }
            },
            // Handler for repeat command
            repeatHandler: {
                type: Function,
                default: function () {
                    this.model.clear();
                }
            },
            autoLoad: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            isEdit: vm => !!vm.model.id,
            isHeader: vm => vm.title && vm.title.length > 0,
            btnSaveText: vm => vm.saveText || (vm.isEdit ? "Сохранить" : "Создать")
        },

        methods: {
            async prepareData() {
                try {
                    await this.model.fetch();
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных");
                    throw e;
                }
            },

            async onSaveClick() {
                // by default handle click as first command
                this.onSaveCommand(this.saveCommands && first(keys(this.saveCommands)));
            },

            async onDeleteClick() {
                try {
                    await this.model.delete();
                    this.$message.success(`Запись удалена`);
                    // use same back handler
                    this.onBackClick();
                } catch (e) {
                    if (this.model && this.model.fatal) {
                        this.$message.error(`Ошика удаления`);
                    } else {
                        throw  e;
                    }
                }
            },

            async onSaveCommand(command) {
                let handler;
                switch (command) {
                    case 'repeat':
                        handler = this.repeatHandler;
                        break;
                    case 'back':
                    default:
                        handler = this.backHandler;
                        break;
                }


                try {
                    // drop all errors before save
                    this.model.clearErrors();
                    await this.model.save();
                    this.$message.success(`Изменения успешно сохранены`);
                    handler.call(this);
                } catch (e) {
                    if (this.model && this.model.fatal) {
                        this.$message.error(`Ошибка сохранения`);
                    }
                }
            },

            onBackClick() {
                // use back handler to provide same action
                this.backHandler.call(this);
            }
        },

        created() {
            if (this.isEdit && this.autoLoad) {
                this.prepareData();
            }
        }
    }
</script>


<template>
    <div v-loading="model.loading">
        <el-page-header v-if="isHeader"
                        @back="onBackClick"
                        :title="backTitle"
                        :content="title"></el-page-header>

        <el-form>
            <slot name="default"></slot>
        </el-form>

        <slot name="edit-toolbar">
            <div class="toolbar" v-if="isEdit">
                <el-button type="primary"
                           :loading="model.saving"
                           @click="onSaveClick">{{btnSaveText}}
                </el-button>

                <el-popconfirm @onConfirm="onDeleteClick"
                               v-if="isEdit"
                               confirmButtonText='Да'
                               cancelButtonText='Отмена'
                               title="Уверены что хотите удалить?">
                    <el-button icon="el-icon-delete"
                               slot="reference"
                               type="danger"></el-button>
                </el-popconfirm>

            </div>

            <div class="toolbar" v-if="!isEdit">
                <el-dropdown v-if="saveCommands"
                             split-button
                             type="primary"
                             @command="onSaveCommand"
                             :loading="model.saving"
                             @click="onSaveClick">{{btnSaveText}}
                    <el-dropdown-menu slot="dropdown" v-if="saveCommands">
                        <el-dropdown-item v-for="(text, cmd) in saveCommands"
                                          :key="cmd"
                                          :command="cmd">{{text}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button v-if="!saveCommands"
                           type="primary"
                           :loading="model.saving"
                           @click="onSaveClick">{{btnSaveText}}
                </el-button>
            </div>
        </slot>
    </div>
</template>


<style scoped lang="scss">
    .toolbar {
        margin-top: 22px;
    }

</style>