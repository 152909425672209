/**
 * The Order model
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Base from "./BaseModel";
import OrderItem from "./OrderItem";
import Formatter from "../utils/Formatter";
import BaseStore from "./BaseStore";
import axios from "axios";
import OrderPayment from "./OrderPayment";
import Counterparty from "./Counterparty";
import OrderHistory from "./OrderHistory";

export default class Order extends Base {
    static entityName = 'order';
    static STATUS_CREATED = 'created';
    static STATUS_CONFIRM = 'confirmed';
    static STATUS_CANCELED = 'canceled';
    static STATUS_CUSTOMER_RECEIVED = 'customer_received';
    static STATUS_INPROGRESS = 'inprogress';
    static STATUS_DELIVERING = 'delivering';

    static DELIVERY_PICKUP = 'pickup';
    static DELIVERY_POST = 'post';
    static DELIVERY_COURIER = 'courier';
    static DELIVERY_OWN_DRIVER = 'own_driver';

    defaults() {
        return {
            id: null,
            number: "",
            status: null,
            access_token: "",
            external_token: null,
            client_name: "",
            client_email: "",
            client_phone: "",
            client_notes: "",
            pay_status: null,
            total: 0,
            balance: 0,
            created_at: null,
            created_by: null,
            updated_at: null,
            updated_by: null,
            manager_notes: null,
            delivery_type: null,
            delivery_cost: 0,
            delivery_date: null,
            delivery_address: "",
            counterparty_id: null,
            counterparty: null,
            items: [],
            isConfirmationRequired: false,
            courier_id: null
        }
    }

    get title() {
        return `Заказ № ${this.number} от ${Formatter.asDate(this.created_at, 'LL')}`;
    }

    /**
     * @return Counterparty|NULL - The instance or null
     * */
    getCounterparty() {
        if (!this._counterpartyInstance) {
            this._counterpartyInstance = new Counterparty();
        }
        if (this.counterparty) {
            this._counterpartyInstance.set(this.counterparty);
            this._counterpartyInstance.sync();
        }
        return this._counterpartyInstance;
    }


    mutations() {
        return {
            ...super.mutations(),
            created_at: (mixed) => {
                return isNaN(new Date(mixed)) ? new Date() : new Date(mixed);
            },
            updated_at: (mixed) => {
                return isNaN(new Date(mixed)) ? new Date() : new Date(mixed);
            }
        }
    }


    getItems(options) {
        if (!this._itemsStoreInstance) {
            this._itemsStoreInstance = new BaseStore(this.items, {
                model: OrderItem,
                entityName: 'order-item',
                filters: [
                    {
                        property: 'order_id',
                        value: this.id
                    }
                ],
                ...options,
            });
        }

        return this._itemsStoreInstance;
    }


    getPayments(options) {
        return new BaseStore(this.payments, {
            model: OrderPayment,
            entityName: 'order-payment',
            filters: [
                {
                    property: 'order_id',
                    value: this.id
                }
            ],
            ...options,
        });
    }


    getHistory(options) {
        return new BaseStore(this.payments, {
            model: OrderHistory,
            filters: [
                {
                    property: 'order_id',
                    value: this.id
                }
            ],
            ...options,
        });
    }


    async fetchDirectData(opts) {
        let resp = await axios.get(`/admin/order/${this.id}`, opts);
        return resp.data;
    }


    static async getDeliveryTypes() {
        let resp = await axios.get('/admin/order/delivery-types');
        return resp.data;
    }


    static async getStatuses() {
        let resp = await axios.get('/admin/order/statuses');
        return resp.data;
    }


    static async getPayStatuses() {
        let resp = await axios.get('/admin/order/pay-statuses');
        return resp.data;
    }


    routes() {
        return {
            ...super.routes(),
            'confirm': 'admin/order/confirm',
            'status-change': 'admin/order/status-change',
            'send-to-customer': 'admin/order/send-to-customer',
            'send-to-delivery': 'admin/order/send-to-delivery',
            'customer-receive': 'admin/order/customer-receive',
            'cancel': 'admin/order/cancel',
        };
    }


    /**
     * Fetch addresses available for delivery
     * */
    async fetchDeliveryAddresses() {
        let resp = await axios.get('/admin/order/delivery-addresses', {params: {id: this.id}});
        return resp.data;
    }


    /**
     * Send request to change status
     * */
    actionStatusChange(newStatus, extraParams = {}) {
        let method = 'PUT';
        let route = this.getRoute('status-change');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);
        let data = {order_id: this.id, status: newStatus};

        return this.request({...extraParams, method, url, data}, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }


    /**
     * Send request to confirm the order
     * */
    actionConfirm(extraParams = {}) {
        let method = 'PUT';
        let route = this.getRoute('confirm');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);
        let data = {order_id: this.id};

        return this.request({...extraParams, method, url, data: data}, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }


    /**
     * Send to delivery request
     * */
    actionSendToCustomer(extraParams = {}) {
        let method = 'PUT';
        let route = this.getRoute('send-to-customer');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);
        let data = {order_id: this.id};

        return this.request({...extraParams, method, url, data}, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }


    /**
     * Send to delivery request
     * */
    actionSendToDelivery(extraParams = {}) {
        let method = 'PUT';
        let route = this.getRoute('send-to-delivery');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);
        let data = {order_id: this.id};

        return this.request({...extraParams, method, url, data}, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }


    /**
     * Do receive action
     * */
    actionCustomerReceive(extraParams = {}) {
        let method = 'PUT';
        let route = this.getRoute('customer-receive');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);
        let data = {order_id: this.id};

        return this.request({...extraParams, method, url, data}, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }


    /**
     * Do cancel action
     * */
    actionCancel(extraParams = {}) {
        let method = 'PUT';
        let route = this.getRoute('cancel');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);
        let data = {order_id: this.id};

        return this.request({...extraParams, method, url, data}, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }
}