/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import BaseStore from "./BaseStore";
import CounterpartyShippingAddress from "./CounterpartyShippingAddress";

export default class Counterparty extends BaseModel {
    static entityName = 'counterparty';

    static TYPE_LEGAL = 'legal';
    static TYPE_PERSONAL = 'personal';

    defaults() {
        return {
            ...super.defaults(),
            name: '',
            type: Counterparty.TYPE_PERSONAL,
            email: '',
            phone: '',
            inn: '',
            bank_id: '',
            bank_name: '',
            bank_account: '',
            corr_account: '',
            legal_address: '',
            actual_address: '',
            notes: '',
            group_ids: [],
            shippingAddresses: [],
            hasRelated: [],
            hasGroups: []
        }
    }

    /**
     * @return boolean - Whether the Counterparty is legal or simple(private, individual)
     * */
    getIsLegal() {
        return this.type === this.constructor.TYPE_LEGAL;
    }

    /**
     * @return BaseStore - The configured store
     * */
    getShippingAddresses(options) {
        return new BaseStore(this.persons, {
            model: CounterpartyShippingAddress,
            entityName: 'counterparty-shipping-address',
            filters: [
                {
                    property: 'counterparty_id',
                    value: this.id
                }
            ],
            ...options,
        });
    }

    /**
     * @return BaseStore - The configured store
     * */
    getHasGroups(options) {
        return new BaseStore(this.persons, {
            entityName: 'counterparty-has-group',
            filters: [
                {
                    property: 'counterparty_id',
                    value: this.id
                }
            ],
            ...options,
        });
    }
}
