<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import Counterparty from "../../models/Counterparty";
import BaseForm from "../../components/Form";

export default {
  name: "Form",
  components: {
    BaseForm
  },
  props: {
    id: Number | String,
    model: {
      type: Counterparty,
      default: () => new Counterparty()
    }
  },
  data() {
    return {
      Counterparty,
      title: this.model.id ? '' : 'Создать контрагента',
      isLegal: this.model.getIsLegal()
    }
  },
  created() {
    this.model.on("change", () => {
      this.isLegal = this.model.getIsLegal();
    });
  }
}
</script>


<template>
  <div class="humanize-container" v-loading="model.loading">
    <base-form :title="title"
               backTitle="Контрагенты"
               :model="model">


      <el-form-item :error="model.errors.type"
                    :show-message="!!model.errors.type">
        <el-radio-group v-model="model.type">
          <el-radio :label="Counterparty.TYPE_PERSONAL">Физ. лицо</el-radio>
          <el-radio :label="Counterparty.TYPE_LEGAL">Юр. лицо</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="!isLegal ? 'ФИО' : 'Название'"
                    :error="model.errors.name"
                    :show-message="!!model.errors.name"
                    required>
        <el-input v-model="model.name"
                  :placeholder="!isLegal ? 'Иванов Иван Петрович' : 'ООО &quot;Рога и копыта&quot; или ИП Иванов'"
                  clearable
                  :maxlength="64"></el-input>
      </el-form-item>

      <el-row :gutter="20">
        <el-col :md="8">
          <el-form-item label="Email"
                        :error="model.errors.email"
                        :show-message="!!model.errors.email"
                        required>
            <el-input v-model="model.email"
                      clearable
                      :maxlength="64"
                      placeholder="ivanov@gmail.com"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Телефон"
                        :error="model.errors.phone"
                        :show-message="!!model.errors.phone">
            <el-input v-model="model.phone"
                      clearable
                      :maxlength="16"
                      placeholder="+71234567890"></el-input>
          </el-form-item>
        </el-col>
      </el-row>


      <!-- Legal only block -->
      <div v-if="isLegal">
        <el-row :gutter="20" type="flex" align="bottom">
          <el-col :md="12">
            <el-form-item label="ИНН"
                          :error="model.errors.inn"
                          :show-message="!!model.errors.inn">
              <el-input v-model="model.inn"
                        clearable
                        :maxlength="16"
                        placeholder="1234567890"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12"></el-col>
          <el-col :md="12">
            <el-form-item label="Банк"
                          :error="model.errors.bank_name"
                          :show-message="!!model.errors.bank_name">
              <el-input v-model="model.bank_name"
                        clearable
                        :maxlength="45"
                        placeholder="ФИЛИАЛ АО БАНК САНКТ-ПЕТЕРБУРГ"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="БИК"
                          :error="model.errors.bank_id"
                          :show-message="!!model.errors.bank_id">
              <el-input v-model="model.bank_id"
                        clearable
                        :maxlength="16"
                        placeholder="1234567890"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Расчетный счет"
                          :error="model.errors.bank_account"
                          :show-message="!!model.errors.bank_account">
              <el-input v-model="model.bank_account"
                        clearable
                        :maxlength="45"
                        placeholder="1234567890"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Корр. счет"
                          :error="model.errors.corr_account"
                          :show-message="!!model.errors.corr_account">
              <el-input v-model="model.corr_account"
                        clearable
                        :maxlength="45"
                        placeholder="1234567890"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Юридический адрес"
                          :error="model.errors.legal_address"
                          :show-message="!!model.errors.legal_address">
              <el-input type="textarea"
                        v-model="model.legal_address"
                        :maxlength="128"
                        :show-word-limit="true"
                        placeholder="123456, г.СПб ул. Ленина 6, оф. 666"
                        :autosize="{ minRows: 3}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Фактический адрес"
                          :error="model.errors.actual_address"
                          :show-message="!!model.errors.actual_address">
              <el-input type="textarea"
                        v-model="model.actual_address"
                        :maxlength="128"
                        :show-word-limit="true"
                        placeholder="123456, г.СПб ул. Ленина 6, оф. 666"
                        :autosize="{ minRows: 3}"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <el-form-item label="Примечания"
                    :error="model.errors.notes"
                    :show-message="!!model.errors.notes">
        <el-input type="textarea"
                  v-model="model.notes"
                  :maxlength="255"
                  :show-word-limit="true"
                  placeholder="Небольшой текст описания для заметок..."
                  :autosize="{ minRows: 2}"></el-input>
      </el-form-item>
    </base-form>

    <!--
            <div class="toolbar" v-if="isEdit">
                <el-button type="primary"
                           @click="onSaveClick">Сохранить
                </el-button>

                <el-popconfirm @onConfirm="onDeleteClick"
                               confirmButtonText='Да'
                               cancelButtonText='Отмена'
                               title="Уверены что хотите удалить?">
                    <el-button icon="el-icon-delete"
                               slot="reference"
                               type="danger"></el-button>
                </el-popconfirm>
            </div>
            <div class="toolbar" v-if="!isEdit">
                <el-dropdown split-button type="primary"
                             @command="onSaveCommand"
                             v-loading="model.loading"
                             @click="onSaveClick">
                    Создать
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="edit">Создать и перейти в карточку Контрагента</el-dropdown-item>
                        <el-dropdown-item command="back">Создать и вернуться назад</el-dropdown-item>
                        <el-dropdown-item command="repeat">Создать и повторить</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>-->

  </div>
</template>


<style scoped lang="scss">
</style>